import React from 'react'
import ProjectsPage from './index'

const Workshops = ({ location }) => {
  const { pathname } = location

  return <ProjectsPage pathname={pathname} />
}

export default Workshops
